@import './settings.scss';
@import './typeographys.scss';

#nav {
	#banner {
		#banner-img {
			background-image: url('../images/top_banner.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 200px;
			position: relative;
			width: 100%;

			#banner-text {
				color: white;
				font-size: 3em;
				font-weight: 600;
				left: 35%;
				line-height: 1em;
				position: absolute;
				text-align: center;
				text-shadow: 2px 2px 4px #000000;
				transform: translate(-50%, -50%);
				top: 50%;
			}
		}

		.fa {
			color: white;
			font-size: 30px;
			left: 10px;
			margin-top: -35px;
			position: absolute;
		}
	}

	.mobile-dropdown {
		display: none;
	}

	.navigation-desktop {
		.navigation {
			background: $almost-black;
			color: $almost-white;
			display: block;
			margin: 0px;
			text-align: center;

			li {
				display: inline-block;
				margin: 0;

				span {
					box-sizing: border-box;
					color: $almost-white;
					cursor: pointer;
					display: block;
					font-family: $navigation;
					font-size: 0.9em;
					font-weight: 700;
					line-height: 1em;
					padding: 0.9em 0.8em 0.8em;
					text-transform: uppercase;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
				}

				.sub-nav {
					background-color: white;
					border: 1px solid $charcoal;
					box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
					display: none;
					min-width: 300px;
					padding: 0px;
					position: absolute;
					z-index: 1;

					li:not(:last-child) {
						border-bottom: 1px solid $charcoal;
					}

					li {
						background: $lightest-gold;
						margin: 0px !important;
						padding: 5px;
						width: 300px;

						a {
							box-sizing: border-box;
							color: black;
							cursor: pointer;
							display: block;
							font-family: $navigation;
							font-size: 0.9em;
							font-weight: 700;
							line-height: 1em;
							margin-bottom: 0px;
							padding: 10px 0px;
							text-transform: uppercase;
							-webkit-box-sizing: border-box;
							-moz-box-sizing: border-box;
						}
					}

					li:nth-last-child(n) {
						padding-bottom: 0px;
					}

					li:hover {
						background: lighten($lightest-gold, 10%);
					}
				}
			}

			#consultant-login,
			#search {
				a {
					background: $maroon;
					border: 2px solid #fff;
					color: #fff;
					padding: 0.4em 0.8em;
				}
				a:hover {
					background: lighten($maroon, 5%);
				}
			}

			#consultant-login {
				a {
					border: 2px solid white;
					color: white;
				}
			}

			#search {
				a {
					background: $gold;
				}

				a:hover {
					background: lighten($gold, 5%);
				}
				&.disabled a {
					background: $light-gray;
					color: $dark-gray;
					cursor: default;
				}
			}

			.dropdown {
				display: inline-block;
				position: relative;
				text-align: left;
			}

			.dropdown:not(:last-child) {
				padding-right: 20px;
			}

			.dropdown:hover .sub-nav {
				display: block;
			}

			#current-page {
				text-decoration: underline;
				text-decoration-thickness: 2px;
				text-underline-position: under;
			}
		}
	}
}

#footer {
	background: $almost-black;
	color: $almost-white;
	min-height: 13vh;
	width: 100%;

	.footer-container {
		display: flex;
		justify-content: space-between;

		.flex-item {
			padding: 10px;

			img {
				max-height: 100px;
			}

			//Need to update to fontawesome v6 eventually
			.fa-x-twitter {
				font-family: sans-serif;
			}

			.fa-x-twitter::before {
				content: '𝕏';
				font-size: 1.1em;
			}

			&.first {
				.social {
					list-style: none;
					margin-top: 0px;
					padding: 0;

					li {
						display: inline-block;
						margin: 0px 0px 0px 10px;
						text-align: center;

						a {
							color: white;
							font-size: 13px;

							span {
								font-family: $times;
							}
						}
					}
				}
				.learn-more {
					font-size: 14px;
					margin-top: 0px;
					padding-left: 8px;

					a {
						color: white;
						font-family: $times;
						font-size: 16px;
						text-decoration: underline;
					}
				}
				.social,
				.learn-more {
					margin-left: 10px;
				}
			}

			&.middle {
				padding-top: 20px;
				text-align: center;

				a {
					color: $almost-black;
					font-family: $default;
					font-size: 14px;
					font-weight: 600;

					.sign-up {
						background: $light-gray;
						border-radius: 2px;
						color: $almost-black;
						margin: 10px auto;
						padding: 8px;
						width: 100px;

						.fa {
							margin-right: 10px;
						}
					}
				}
			}

			&.last {
				.image-right {
					float: right;
					text-align: right;

					#logo {
						margin-bottom: 15px;
					}

					img {
						display: inherit;
						margin: 0 auto;
					}

					#member-img {
						height: 8px;
					}
				}
			}
		}
	}
}
