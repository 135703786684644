@import './settings.scss';
@import './typeographys.scss';

// Content classes and styles, taken from txssc.txstate.edu
.content {
	height: 100%;
	margin: 0 auto;
	max-width: 1200px;
	padding: 10px 10px 20px 10px;

	.left-full {
		display: block !important;
	}
	.no-margin-top {
		margin-top: 0 !important;
	}
	.no-margin-bottom {
		margin-bottom: 0 !important;
	}
	.no-margin-left {
		margin-left: 0 !important;
	}
	.no-margin-right {
		margin-right: 0 !important;
	}

	section,
	div,
	p {
		> .indented {
			display: inline-block;
			margin-left: 5%;
		}
	}

	a,
	a:visited {
		color: $maroon;
		text-decoration: underline;
	}

	a:hover {
		color: $light-maroon;
	}
	.masthead {
		margin: 20px 0 35px 0;
		text-align: center;
		border-bottom: 1px solid #e7e6e4;

		hgroup {
			padding: 0 0 10px 0;
			text-align: center;
			width: 100%;

			h1 {
				font-size: 20px;
				margin: 0;
				color: #767676;
			}

			h2 {
				font-size: 36px;
				margin: 0;
				display: inline-block;
				text-align: center;
			}
		}
	}

	.image {
		&.large-image,
		&.extra-large-image,
		&.x-extra-large-image {
			text-align: center;

			img {
				border: 4px solid $border;
				max-width: 450px;
				padding: 10px;
				width: 90%;
			}

			img:hover {
				cursor: pointer;
			}

			.subtext {
				color: $dark-gray;
				display: block;
				margin-top: -5px;
				font-size: 12px;
			}
		}

		&.extra-large-image {
			img {
				max-width: 650px;
			}
		}

		&.x-extra-large-image {
			img {
				max-width: 900px;
			}
		}
	}

	.modal {
		background-color: rgb(0, 0, 0);
		background-color: rgba(0, 0, 0, 0.4);
		height: 100%;
		left: 0;
		overflow: auto;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1;
	}

	.modal-content {
		background-color: white;
		border: 1px solid $border;
		margin: 15% auto;
		padding: 20px;
		width: 75%;
	}

	.law-ol-list {
		ol {
			counter-reset: list;
		}
		ol > li {
			list-style: none;
		}

		ol > li:before {
			content: '(' counter(list, lower-alpha) ') ';
			counter-increment: list;
		}

		ol.numerical > li:before {
			content: '(' counter(list) ') ';
			counter-increment: list;
		}
	}

	.callout {
		margin: 1em auto;
		max-width: 100%;
		padding: 0.2em 1em;
		border: 1px solid darken($border, 4%);
		background: $callout-gray;

		p,
		ol,
		ul,
		li {
			font-family: $announcement;
			font-size: 16px;
			font-weight: 300;
			margin: 0.8em 0;
			width: auto;
			list-style: none;
			a,
			.fa {
				font-weight: 600;
			}

			.evidence {
				font-weight: 600;
				list-style: none;
			}

			.download-link {
				color: $maroon;
				margin-right: 5px;
				position: relative;
				top: 2px;
			}
		}

		&.emphasized {
			border: 4px solid black;
			background: #ebba45; // Yellow gold background
			color: #000; // Ensure text is readable on the yellow background

			p,
			ol,
			ul,
			li {
				font-weight: 400; // Slightly bolder for emphasis
				font-size: 18px; // Larger font for emphasis
			}
		}

		&.numbered {
			ol {
				list-style: decimal; // Apply numbering to the <ol>
				padding-left: 1.5em; // Ensure proper indentation

				li {
					list-style: inherit !important; // Ensure <li> inherits from <ol>
				}
			}
		}
	}

	.faq-callout {
		margin: 1em auto;
		max-width: 100%;
		padding: 0.2em 1em;
		border: 1px solid darken($border, 4%);
		background: $callout-gray;

		p,
		ol,
		ul,
		li {
			font-family: $announcement;
			font-size: 16px;
			font-weight: 300;
			margin: 0.8em 0;
			width: auto;

			img {
				max-width: 100%;
				margin: 20px auto;
			}

			a,
			.fa {
				font-weight: 600;
			}

			.evidence {
				font-weight: 600;
				list-style: none;
			}

			.download-link {
				color: $maroon;
				margin-right: 5px;
				position: relative;
				top: 2px;
			}
		}
	}

	.banner-list {
		border-radius: 3px;
		border: 1px solid rgba(170, 170, 170, 0.65);
		background: #e1ddd7;
		margin: 20px 0;

		> h3,
		.header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 0;
			text-align: center;
			height: 3.25em;
			box-sizing: border-box;
			color: rgba(255, 255, 255, 0.9);
			background: lighten($maroon, 3%);
			border-radius: 2px;
			border-bottom: 4px solid $gold;
		}

		.header {
			padding: 0.6em 0.75em;
			font-style: italic;
			height: auto;
			font-size: 1.3em;

			&.spanning {
				border-bottom: none;
				font-size: 1.7em;
				letter-spacing: 2px;
				text-align: left;
				background: linear-gradient(to right top, $maroon, $gold 175%);
			}
		}

		ul,
		ol {
			padding: 0.5em 4.5%;
		}
	}

	.file-download-imp-btn,
	.file-download-not-imp-btn,
	.external-link-btn {
		background: $maroon;
		border: 1px solid #481012;
		color: #fff;
		padding: 15px 10px;
		font-family: $sans-serif;
		position: relative;

		a {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}

		.fa {
			font-size: 24px;
		}

		.link-and-fa {
			display: block;
			margin-right: 40px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			.fa {
				margin-right: 10px;
				padding-bottom: 1px;
				top: 4px;
			}
			.link {
				font-size: 20px;
				font-weight: 600;
			}
		}

		.file-type {
			float: right;
			background: $light-maroon;
			padding: 15px 10px 17px 10px;
			margin: -42px -10px -11px -10px;
			text-align: center;
			width: 30px;
		}
	}

	.file-download-imp-btn {
		.link-and-fa {
			.fa {
				position: relative;
				top: 4px;
			}
		}
	}

	.file-download-not-imp-btn {
		background: $dark-sandstone;
		border: 1px solid #685638;
		color: $maroon;

		.link-and-fa {
			.fa {
				border-bottom: 2px solid $maroon;
				&.fa-no-under {
					border-bottom: none;
				}
			}
		}

		.file-type {
			background: darken($dark-sandstone, 10%);
			color: $maroon;
		}
	}

	.external-link-btn {
		background: #d8d8d8;
		border: 1px solid #6c6c6c;
		color: $maroon;

		.link-and-fa {
			.fa {
				border-bottom: none;
				position: relative;
				top: 4px;
			}
		}

		.file-type {
			background: #c2c2c2;
		}
	}

	.file-download-imp-btn:hover {
		background: lighten($maroon, 5%);
		cursor: pointer;

		.file-type {
			background: lighten($light-maroon, 5%);
		}
	}

	.file-download-not-imp-btn:hover {
		background: darken($dark-sandstone, 5%);
		cursor: pointer;

		.file-type {
			background: darken($dark-sandstone, 15%);
		}
	}

	.external-link-btn:hover {
		background: darken(#d8d8d8, 5%);
		cursor: pointer;

		.file-type {
			background: darken(#c2c2c2, 5%);
		}
	}

	.centered {
		text-align: center;
		width: 100%;
		max-width: 1160px;

		&.lg-text {
			margin-top: 0;
			font-size: 1.8em;
		}

		&.flex-video {
			margin: auto;
			max-width: 850px;
			padding-bottom: 30px;
		}
	}

	.left-full {
		text-align: left;
		width: 100%;
		clear: both;
	}

	.center-full {
		text-align: center;
		width: 100%;
		clear: both;
	}

	.expander {
		padding: 0;
		list-style: none;
		margin-top: 0;

		.toc-section {
			margin-bottom: 0.9em;
			border: none;
			background: none;
			width: auto;

			.toc-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0.5em;
				background: $light-gold;

				cursor: pointer;

				h3 {
					margin: 0;
					font-size: 20px;
				}

				&:hover {
					transition: ease-in-out 0.2s;
					background: lighten($light-gold, 5%);
				}
			}

			.expand-icon {
				// Icon styling here
				&:before {
					content: '\f067'; // Plus icon
				}
				&.expanded:before {
					content: '\f068'; // Minus icon
				}
			}

			.expandable {
				margin-top: 0.5em;
				background: #f1f1f1;
				color: $dark-gray;
				padding: 0.5em;

				ul {
					list-style: disc;
				}

				p {
					margin: 0;
					font-size: 16px;
				}

				b {
					font-weight: 600;
					color: $maroon;
				}
			}
		}
	}

	.tool-tip {
		margin: 0 auto;
		padding: 10px 0px 20px;
		width: 95%;
		text-align: center;
		color: $dark-gray;

		.tip-text {
			font-size: 12px;

			i.fa-arrow-up {
				margin-left: -1em;
			}
		}
	}

	.appendix {
		border-collapse: collapse;
		width: 100%;
		margin: 30px auto;

		tr:nth-child(even) {
			background-color: lighten($dark-sandstone, 5%);
		}

		table,
		th,
		td {
			border: 1px solid;
			padding: 10px;
		}

		th {
			background: $maroon;
			color: #fff;
		}

		td {
			ul {
				li {
					font-size: 1rem;
				}
			}
		}
	}

	.cta-button {
		display: inline-block;
		margin: 20px 0 20px 10px;
		padding: 12px 20px;
		background-color: $maroon;
		color: $almost-white;
		font-weight: 600;
		font-size: 16px;
		text-align: center;
		text-decoration: none;
		border-radius: 8px;
		cursor: pointer;
		transition: background-color 0.3s ease, transform 0.2s ease;

		&:hover {
			background-color: $light-maroon;
			transform: translateY(-2px);
		}

		&:active {
			transform: translateY(1px);
		}

		&.inactive {
			background-color: $lightest-maroon;
			color: $light-gray;
			cursor: not-allowed;
			pointer-events: none;
			transform: none;

			&:hover {
				background-color: $lightest-maroon;
				transform: none;
			}
		}

		&.left-align {
			margin: 20px 0 20px 0;
		}
	}
}
